<template>
	<div class="login-container" v-if="computedValue === `ko`">
		<span class="page-description"
			>아이디와 이메일을 입력하시면<br />비밀번호 재설정 메일을 보내드립니다.
		</span>
		<div class="form">
			<div class="form__group">
				<!-- 아이디 -->
				<label for="inputId">아이디</label>
				<input
					class="form__group--input"
					id="inputId"
					v-model="form.userId"
					type="text"
					required="required"
					placeholder="아이디"
					maxlength="12"
				/>
			</div>
			<div class="form__group">
				<!-- 이메일 -->
				<label for="input-1">이메일</label>
				<input
					id="input-1"
					v-model="form.userEmail"
					type="text"
					required="required"
					placeholder="이메일"
					maxlength="60"
				/>

				<!-- 유효성 검사 알림 -->
				<div v-show="emailState == false" class="validateWarning">
					이메일 형식이 일치하지 않습니다.
				</div>
				<div v-show="emailState == true" class="validateSucccess">
					유효한 이메일 형식입니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">이메일 형식으로 입력해주세요.</div>
			</div>
			<button
				class="btn__form"
				:class="{ btnDisabled: !formState }"
				:disabled="!formState"
				@click="findPw()"
			>
				비밀번호 찾기
			</button>
		</div>
	</div>
	<div class="login-container" v-else-if="computedValue === `en`">
		<span class="page-description"
			>If you enter your Id and email, <br />we will send you an email to reset
			your password.
		</span>
		<div class="form">
			<div class="form__group">
				<!-- 아이디 -->
				<label for="inputId">ID</label>
				<input
					class="form__group--input"
					id="inputId"
					v-model="form.userId"
					type="text"
					required="required"
					placeholder="ID"
					maxlength="12"
				/>
			</div>
			<div class="form__group">
				<!-- 이메일 -->
				<label for="input-1">Email</label>
				<input
					id="input-1"
					v-model="form.userEmail"
					type="text"
					required="required"
					placeholder="Email"
					maxlength="60"
				/>

				<!-- 유효성 검사 알림 -->
				<div v-show="emailState == false" class="validateWarning">
					The email format is incorrect
				</div>
				<div v-show="emailState == true" class="validateSucccess">
					This is a valid email.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					Please enter your email in the correct format.
				</div>
			</div>
			<button
				class="btn__form"
				:class="{ btnDisabled: !formState }"
				:disabled="!formState"
				@click="findPw()"
			>
				Find Password
			</button>
		</div>
	</div>
</template>

<script>
import forgotpwService from '../../services/forgotpwService';
import { validateEmail } from '@/utils/validation';
export default {
	data() {
		return {
			form: {
				userId: '',
				userEmail: '',
			},
			duplicateState: null,
		};
	},
	computed: {
		emailState() {
			if (this.form.userEmail != '') {
				return validateEmail(this.form.userEmail);
			} else {
				return null;
			}
		},
		formState() {
			if (this.form.userId && this.emailState == true) {
				return true;
			} else {
				return false;
			}
		},
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		async findPw() {
			//중복확인 userinfoDataService`
			let id = this.form.userId;
			const formData = {
				userId: this.form.userId,
				userEmail: this.form.userEmail,
			};

			forgotpwService
				.fetchinpw(id)
				.then(res => {
					//회원가입한 아이디의 경우
					if (res.data) {
						console.log('유효한 회원 입니다.');
						this.$router.push('/findpw/complete');

						console.log(id);

						forgotpwService.sendpw(formData).then(res => {
							if (res.data.message == 'SUCCESS') {
								console.log(formData);
							}
						});
						//없는 아이디의 경우
					} else if (!res.data) {
						console.log('없는 회원입니다.');
						this.message = '없는 아이디 입니다. 다시 한번 확인해 주세요';
						alert('없는 아이디 입니다. 다시 한번 확인해 주세요');
						this.idState = null;
					}
				})
				.catch(e => {
					console.log(e);
				});
		},
	},
};
</script>

<style></style>
